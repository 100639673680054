// Customizable Area Start
import React from "react";

import LandingPageController, {
    Props,
} from "./LandingPageController";
import {Box, Typography, Button, TextField,MenuItem,Select,FormControl,styled,InputAdornment} from "@mui/material";
import { man_Img, women_Img,image_first,image_second,image_third,image_forth,image_fifth,
    image_sixth,arrowDown,arrowUp,coin_image,key_image,arrowOpen,arrowClose,calender
} from "./assets";
import { Formik } from "formik";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import moment from "moment";

const MainWrapper = styled(Box)(({theme}) => ({
   
    "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":{
        padding:"0px !important",
     },
    "& .mobileMainWrapper": {
        display:"flex",
        width:"100vw",
        flexDirection:"column" as "column",
        position: "relative" as "relative",
        top: "140px",
        [theme.breakpoints.down(901)]: {
            display:"flex",
            width:"100vw",
            flexDirection:"column" as "column",
            position: "relative" as "relative",
            top: "82px",
            left: 0,
    }},
    " & .thirdContainer":{
        display:"flex",
        width:"100vw",
        paddingBottom:"1.64vw",
        [theme.breakpoints.down(901)]: {
            flexDirection:"column" as "column",
            justifyContent:"center",
            alignItems:"center"
        },
    },
    "& .thirdBoxCoin":{
        [theme.breakpoints.down(901)]: {
            display:"none"
        },
        width:"11.8vw",
    },
" & .thirdBoxCoinImg": {
    overflow:"hidden",
    [theme.breakpoints.down(901)]: {
        display: "none",
    },
    paddingTop: "6.02vw",
},
" & .thirdLeftBox": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent:"center",
    alignItems:"center",
    width: "60vw",
    [theme.breakpoints.down(901)]: {
        width: "80vw",
    },
},
" & .thirdBoxContent": {
    display: "flex",
    flexDirection: "column" as "column",
    [theme.breakpoints.down(901)]: {
        width:"100vw",
        justifyContent:"center",
        alignItems:"center"
    },
},
" & .thirdBoxTypos": {
    display: "flex",
    flexDirection: "column" as "column",
},
" & .thirdBoxTypoOne": {
    fontFamily: "Poppins",
    fontSize: "2.3vw",
    fontWeight: 600,
    lineHeight: "5vw",
    textAlign: "left" as "left",
    color: "rgba(11, 9, 10, 1)",
    [theme.breakpoints.down(901)]: {
        textAlign: "center" as "center",
        fontSize: "4.6vw",
        lineHeight: "10vw",
    },
}," & .inputMonths": {
    fontSize: "0.76vw",
    fontFamily: "Poppins",
    fontWeight: 700,
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":{
        padding:'1.15vw 0.5vw !important',
    },
    [theme.breakpoints.up(1800)]: {
        padding: "0.04vw 0.5vw !important",
    },
    [theme.breakpoints.down(1800)]: {
        fontSize: "0.85vw",
        padding: "0.02vw 0.5vw !important",
    },
    [theme.breakpoints.down(1400)]: {
        fontSize: "1vw",
        padding: "0.15vw 0.5vw !important",
    },
    [theme.breakpoints.down(1200)]: {
        fontSize: "1vw",
        padding: "0.30vw 0.5vw !important",
    },
    [theme.breakpoints.down(901)]: {
        fontSize: "2vw",
        padding: "1vw 0.5vw !important",
    },
    [theme.breakpoints.down(450)]: {
        fontSize: "3.6vw",
        padding: "1.9vw 0.5vw !important",
    },
},
" & .thirdBoxTypoTwo": {
    fontFamily: "Poppins",
    fontSize: "1.31vw",
    fontWeight: 700,
    lineHeight: "1.86vw",
    textAlign: "left" as "left",
    color: "rgba(0, 0, 0, 1)",
    width: "41.07vw",
    paddingBottom: "3.55vw",
    [theme.breakpoints.down(901)]: {
        display:"none",
    },
},
" & .thirdCalculate": {
    background: "rgba(29, 54, 187, 1)",
    padding: "0.82vw 0.6vw 0 0.6vw",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    [theme.breakpoints.down(901)]: {
        width:"90vw",
        padding:"2.5vw 0"
    },
},
"& .thirdBoxTypoTwoMobile":{
    [theme.breakpoints.up(901)]: {
        display:"none",
    },
        [theme.breakpoints.down(901)]: {
            display:"block",
            textAlign: "center" as "center",
            fontSize: "2.4vw",
            fontWeight: 400,
            lineHeight: "3.2vw",
            width:"90vw",
            fontFamily: "Poppins",
            color: "rgba(87, 88, 90, 1)",
            paddingBottom: "3.55vw",
        },
},
" & .thirdCalculateText": {
    fontFamily: "Poppins",
    fontSize: "0.98vw",
    fontWeight: 700,
    textAlign: "left" as "left",
    color: "rgba(255, 255, 255, 1)",
    paddingBottom: "1.64vw",
    [theme.breakpoints.down(901)]: {
        fontSize: "3.7vw",
        textAlign: "center" as "center",
        paddingBottom:"4vw",
    },
},
" & .thirdRightBox": {
    width: "72vw",
    [theme.breakpoints.down(901)]: {
        width:"90vw"
    },
},
"& .thirdRightBoxImg": {
    width: "36.7vw",
    height: "32.5vw",
    [theme.breakpoints.down(901)]: {
        width:"70vw",
        height:"60vw"
    },
},
" & .forthContainer": {
    display: "flex",
    flexDirection: "row" as "row",
    width: "100vw",
    padding: "4.92vw",
    paddingLeft: "12.1vw",
    background: "rgba(247, 247, 247, 1)",
    [theme.breakpoints.down(901)]: {
        flexDirection: "column" as "column",
        padding:"1vw",
        width: "99vw",
    },
},
" & .forthContLeft": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "50vw",
    [theme.breakpoints.down(901)]: {
        width: "100vw",
        justifyContent:"center",
        alignItems:"center", 
        padding:"7vw"   
    },
},
" & .forthLeftTypoOne": {
    fontFamily: "Poppins",
    fontSize: "3.01vw",
    fontWeight: 700,
    lineHeight: "5vw",
    color: "rgba(0, 0, 0, 1)",
    width: "35.14vw",
    paddingBottom: "2.19vw",
    [theme.breakpoints.down(901)]: {
        fontSize: "3.5vw",
        lineHeight: "6vw",
        textAlign:"center",
        width: "90vw",
    },
},
" & .forthLeftTypoTwo": {
    fontFamily: "Poppins",
    fontSize: "1.42vw",
    fontWeight: 400,
    lineHeight: "2.3vw",
    color: "rgba(0, 0, 0, 1)",
    width: "38.33vw",
    [theme.breakpoints.down(901)]: {
        fontSize: "2.42vw",
        fontWeight: 400,
        lineHeight: "3.8vw",
        color: "rgba(0, 0, 0, 1)",
        textAlign:"center",
        width: "90vw",
    },
},
" & .forthContRight": {
    width: "40vw",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    [theme.breakpoints.down(901)]: {
        width: "100vw", 
        justifyContent: "center",
    },
},
" & .forthContRightImg": {
    width: "28.47vw",
    height: "28.47vw",
    [theme.breakpoints.down(901)]: {
        width:"40vw",
        height:"40vw"
    },
},
" & .fifthContainer": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.54vw 0.54vw 2.73vw 0.54vw",
},
" & .fifthTitle": {
    fontFamily: "Poppins",
    fontSize: "2.9vw",
    fontWeight: 700,
    lineHeight: "4.38vw",
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    padding: "2.73vw",
    paddingTop: "4.65vw",
},
" & .fifthSubCon": {
    display: "flex",
    flexDirection: "row" as "row",
    position: "relative" as "relative",
    [theme.breakpoints.down(901)]: {
        flexDirection: "column" as "column",
    },
},
" & .fifthBox": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "23vw",
    gap: "1vw",
    [theme.breakpoints.down(901)]: {
        height: "30vw",
        paddingBottom:"10vw",
    },
},
" & .fifthBoxFirst": {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "23vw",
    paddingBottom:"4.6vh",
    paddingTop:"2vh",
    gap: "1vw",
    [theme.breakpoints.down(901)]: {
        height: "30vw",
        paddingBottom:"10vw",
    },
},
" & .fifthMainImg": {
    height: "6.24vw",
    width: "6.24vw",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down(901)]: {
        height: "15vw",
        width: "15vw",
    },
},
" & .fifthSubImg": {
    height: "1.64vw",
    width: "1.64vw",
    borderRadius: "50%",
    background: "rgba(25, 25, 25, 1)",
    margin: "0.82vw",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(901)]: {
        display:"none"
    },    
},
" & .fifthNumber": {
    fontFamily: "Poppins",
    fontSize: "1.31vw",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "center" as "center",
    [theme.breakpoints.down(901)]: {
        display:"none"
    },
},
" & .fifthHeading": {
    fontFamily: "Poppins",
    fontSize: "1.31vw",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    width: "26.72vw",
    [theme.breakpoints.down(901)]: {
        width:"70vw",
        fontSize: "2.9vw",
        lineHeight:"6vw",
    }
},
" & .fifthSubHeading": {
    fontFamily: "Poppins",
    fontSize: "0.87vw",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center" as "center",
    width: "22.07vw",
    lineHeight:"2.65vh",
    [theme.breakpoints.down(901)]: {
        fontSize: "1.9vw",
        width: "70vw",
        lineHeight:"3vw",
    }
},
" & .buttonSubmitAppTwo": {
    background: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "1.09vw",
    fontWeight: 700,
    borderRadius: "8px",
    width: "19.33vw",
    height: "3.06vw",
    border:"none",
    // padding: "0.82vw",
    [theme.breakpoints.down(901)]: {
        fontSize: "2.1vw",
        fontWeight: 700,
        borderRadius: "8px",
        width: "33.33vw",
        height: "5.06vw",
        // padding: "1.82vw", 
       }
},
" & .thirdCalculateTabs": {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.54vw",
    [theme.breakpoints.down(901)]: {
        flexDirection: "column" as "column",
        gap: "2vw",
    },
},
" & .labels": {
    fontSize: "0.76vw",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down(901)]: {
        fontSize: "2.7vw",
    },
},
" & .input": {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    padding:0,
    color: "rgba(107, 107, 107, 1)",
    fontSize: "0.87vw",
    fontFamily: "Inter",
    fontWeight: 400,
    width: "100%",
    [theme.breakpoints.down(901)]: {
        fontSize: "1.2vw",
        padding:0
    },
},
" & .inputMon": {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    color: "rgba(107, 107, 107, 1)",
    fontFamily: "Inter",
    fontWeight: 400,
    width: "100%",
    [theme.breakpoints.down(901)]: {
        fontSize: "1.2vw",
    },
},
" & .calenderIcon":{
    pointerEvents:"none",
    position:"absolute",
    right:5,
    top:15,
    width:"1.5vw",
    height:"1.5vw",
    backgroundColor:'white',
    [theme.breakpoints.down(1200)]: {
        width:"2vw",
        height:"2vw",  
        top:12.5,
    },
    [theme.breakpoints.down(901)]: {
        width:"4vw",
        height:"4vw",  
        top:14,
    },
    [theme.breakpoints.down(700)]: {
        width:"5.5vw",
        height:"2.5vh", 
        top:10,
    },
    [theme.breakpoints.down(600)]: {
        width:"5.5vw",
        height:"2.5vh", 
        top:"3vw",
    },
    [theme.breakpoints.down(450)]: {
        width:"5.5vw",
        height:"2.5vh", 
        top:14,
    },
},

" & .calculateButton": {
    textTransform: "none" as "none",
    fontFamily: "Poppins",
    background: "rgba(242, 242, 247, 1)",
    color: "rgba(29, 54, 187, 1)",
    fontSize: "1.09vw",
    fontWeight: 700,
    borderRadius: "6px",
    padding: "1.7vh 0.82vw",
    width: "10.56vw",
    border:"none",
    [theme.breakpoints.up(1800)]: {
        padding: "1.85vh 0.82vw",
    },
    [theme.breakpoints.down(1800)]: {
        padding: "0.91vw 0.82vw",
    },
    [theme.breakpoints.down(1400)]: {
        padding: "1.2vw 0.82vw",
    },
    [theme.breakpoints.down(1200)]: {
        padding: "1.32vw 0.82vw",
    },
    [theme.breakpoints.down(901)]: {
        fontSize: "3vw",
        width:"85vw",
        lineHeight:"7vw",
        padding: "0.52vw 0.82vw",
    },
    [theme.breakpoints.down(450)]: {
        padding: "1.9vw 0.82vw",
    },
},
" & .firstField": { 
    width: "14.67vw",
    padding:0,
    color: "rgba(107, 107, 107, 1)",
    fontWeight:400,
    [theme.breakpoints.down(901)]: {
        width:"85vw",
        padding:0,
    },
    "& .MuiOutlinedInput-input": {
        [theme.breakpoints.up(1400)]: {
            padding: "1vw 1vw 1vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(1400)]: {
            padding: "1.25vw 1vw 1.25vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(1200)]: {
            padding: "1.4vw 1vw 1.4vw 0",
            fontSize: "1vw",
        },
        [theme.breakpoints.down(901)]: {
        padding: "1.5vw 1vw 1.5vw 0",
        fontSize: "3vw",
        }, 
        [theme.breakpoints.down(600)]: {
            padding: "3.5vw 1vw 3.5vw 0",
            fontSize: "3vw",
        },
        [theme.breakpoints.down(600)]: {
            padding: "3.5vw 1vw 3.5vw 0",
            fontSize: "3vw",
        },    
    },
    "& .MuiInputAdornment-root":{
        [theme.breakpoints.up(1200)]: {
            fontSize: "1vw",
            },
        [theme.breakpoints.down(1200)]: {
            fontSize: "1vw",
            },
        [theme.breakpoints.down(901)]: {
            fontSize: "3vw",
        },     }
 },
" & .secondField": { width: "14.67vw",
    fontWeight:400,
    color: "rgba(107, 107, 107, 1)",
    "& .MuiInputBase-input": {
        padding: "1vw",
        [theme.breakpoints.down(901)]: {
            padding: "2vh 2vw",
            fontSize: "2vw",
            },
    },
    [theme.breakpoints.down(901)]: {
        width:"85vw",
        padding:0
    },
 },
" & .thirdField": { 

    width: "8.7vw" ,
    "& .MuiFormControl-root": {
        padding: 0,
    },
    [theme.breakpoints.down(901)]: {
        width:"85vw"
    },
},
" & .buttonCalBox": { width: "10.56vw",
    [theme.breakpoints.down(901)]: {
        width:"85vw",
        paddingTop:"2.1vh",
    },
 },
" & .sixthContainer": {
    display: "flex",
    width:"100vw",
    marginBottom:"10vw",
    marginTop:"6vw",
    justifyContent: "center",
    alignItems:"center",
    [theme.breakpoints.down(901)]: {
        paddingLeft: 0,
        position:"relative",
        paddingBottom:"22vw",
    }
},
" & .sixthMain": {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems:"center",
    justifyContent:"center",
    width: "64vw",
    [theme.breakpoints.down(901)]: {
        width: "90vw",
     }
},
" & .sixthContHeading":{
    color: "rgba(0, 0, 0, 1)",
    fontSize:"3.17vw",
    fontFamily:"Poppins",
    fontWeight:700,
    textAlign:"left" as "left",
    padding:"3.5vw 2vw",
    [theme.breakpoints.down(901)]: {
       width:"65vw",
       textAlign:"center" as "center",
       paddingLeft:"35vw",
    },
  },
  " & .sixthFAQbox":{
    width: "64vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    [theme.breakpoints.down(901)]: {
        width:"100vw",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
     },
  },
  " & .sixthQuesBox":{
    border: "1px solid rgba(209, 209, 214, 1)",
    borderRadius:"8px",
    background: "rgba(255, 255, 255, 1)",
    width:"48vw",
    padding:"1.09vw",
    margin:"1.09vw",
    display:"flex",
    flexDirection:"column" as "column",
    [theme.breakpoints.down(901)]: {
        width:"96vw",
        zIndex:2,
     },
  },
  " & .sixthBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    [theme.breakpoints.down(901)]: {
    },
  },
  " & .sixthContQuestions":{
    color: "rgba(0, 0, 0, 1)",
    fontSize:"0.93vw",
    fontFamily:"Poppins",
    fontWeight:700,
    [theme.breakpoints.down(901)]: {
        fontSize:"2vw",
    },
  },
  " & .faqAns":{
    color: "rgba(0, 0, 0, 1)",
    fontSize:"0.93vw",
    fontFamily:"Poppins",
    fontWeight:400,
    [theme.breakpoints.down(901)]: {
        fontSize:"2vw",
        width:"56vw"
  }},
  " & .sixthContRight":{
    width:"11vw",
    position: "absolute",
    right: "0",
    overflow:"hidden",
    [theme.breakpoints.down(901)]: {
        position:"absolute",
        left:"80vw",
        top:"51vw",
        width:"18vw",
    },
  },
  " & .sixthContRightImg":{
    width:"14vw",
    height:"20vw",
    [theme.breakpoints.down(901)]: {
        width:"25vw",
        height:"30vw",
    },
  },
  " & .arrowOpen":{height:"2.36vw",width:"2vw",
    padding:"0.38vw",
    [theme.breakpoints.down(901)]: {
        height:"4.36vw",
        width:"3vw"
    },
  },
  " & .arrowClose":{height:"1.36vw",width:"1.36vw",
    padding:"0.38vw"
  },
  " & .arrowDown":{
    [theme.breakpoints.down(901)]: {
        display:"none"
    },
    width:"15.75vw",height:"19.16vw",
    position:"absolute" as "absolute",left:"18vw",bottom:"8vw",
},
" & .arrowUp": {
    [theme.breakpoints.down(901)]: {
        display:"none"
    },
    width: "15.75vw", height: "19.16vw",
    position: "absolute" as "absolute", left: "46vw",bottom:"7vw",
},
" & .form":{
    display :"flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: "0.54vw",
    [theme.breakpoints.down(901)]: {
        flexDirection: "column" as "column",
        gap: "2vw",
    },
} ,
" & .inputDateBox": {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    padding:"1.8vh 0",
    color: "rgba(107, 107, 107, 1)",
    fontSize: "0.87vw",
    fontFamily: "Inter",
    fontWeight: 400,
    width: "100%",
    display:"flex",
    justifyContent:"flex-end",
    paddingRight:"0.7vw",
    position:"relative",
    [theme.breakpoints.down(901)]: {
        fontSize: "1.2vw",
        padding:"1.7vh 0",
        paddingRight:"2vw",
    },
},
"& .dateInput": {
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    height:'3.4vw',
    [theme.breakpoints.up(1400)]: {
        height:'3.44vw',
    },
    [theme.breakpoints.down(1400)]: {
        height:'3.94vw',
    },
    [theme.breakpoints.down(1200)]: {
        height:'4.3vw',
    },
    [theme.breakpoints.down(901)]: {
        height:'7.2vw',
    },
    [theme.breakpoints.down(600)]: {
        height:'11.2vw',
    },
    [theme.breakpoints.down(450)]: {
        height:'11.2vw',
    }
  },
  " & .inputDateClass": {
    position: 'absolute',
    width: '10vw',
    fontSize:"1vw",
    fontWeight:400,
    left: "0.3vw",
    top: "0.9vw",
    background: "rgba(255, 255, 255, 1)",
    paddingLeft:"1vw",
    [theme.breakpoints.down(1400)]: {
        top: "0.7vw",
        width:"12vw"
    },
    [theme.breakpoints.down(901)]: {
        top:"2vw",
        width:"20vw",
        fontSize:"2.5vw",
    },
    [theme.breakpoints.down(700)]: {
        top:"1vw",
        width:"22vw",
        fontSize:"3vw",
    },
    [theme.breakpoints.down(600)]: {
        top:"2.5vw",
        width:"24vw",
        fontSize:"3vw",
    },
    [theme.breakpoints.down(450)]: {
        top:"2.5vw",
        width:"33vw",
        fontSize:"3.5vw",
    },
},
" & .inputDateSubClass" :{
    width: '100%', outline: 'none', border: 'none',borderRadius:"6px",
    color: "rgba(69, 69, 69, 1)",
    fontFamily:"Poppins",
    paddingLeft:"1vw",
    paddingRight:"0.5vw"
},      
  }))
// Customizable Area End

export default class LandingPage extends LandingPageController {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <MainWrapper>
                <ThemeProvider theme={theme}>
                    <div>
                        <NavigationMenu navigation={this.props.navigation} id="">
                            <div className="mobileMainWrapper">
                                <Box className="thirdContainer">
                                    <Box className="thirdBoxCoin">
                                        <img className="thirdBoxCoinImg" src={coin_image.default} />
                                    </Box>
                                    <Box className="thirdLeftBox">
                                        <Box className="thirdBoxContent">
                                            <Box className="thirdBoxTypos">
                                                <Typography className="thirdBoxTypoOne">Rent Now Pay Later</Typography>
                                                <Typography className="thirdBoxTypoTwo">Convert your annual rent contract into monthly payments with our Sharia-compliant service</Typography>
                                                <Typography className="thirdBoxTypoTwoMobile">Convert your annual rent contract into monthly payments according to Sharia laws</Typography>
                                            </Box>
                                            <Box className="thirdCalculate">
                                                <Typography className="thirdCalculateText">Calculate your installments with ease</Typography>

                                                <Box className="thirdCalculateTabs">
                                                    <Formik
                                                    data-test-id="formik"
                                                        initialValues={{
                                                            annualRent: "",
                                                            moveInDate: "",
                                                            duration: ""
                                                        }}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setSubmitting(false);
                                                        }}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            isSubmitting,
                                                        }) => (
                                                            <form autoComplete="off" noValidate onSubmit={handleSubmit} className="form">
                                                                <Box className="firstField">
                                                                    <label className="labels">Annual Rent Value (SAR)</label>
                                                                    <TextField
                                                                    data-test-id="annualRent"
                                                                        id={"annualRent"}
                                                                        value={this.state.annualRent}
                                                                        name="annualRent"
                                                                        onChange={this.handleRentChange}
                                                                        fullWidth
                                                                        className="input"
                                                                     placeholder="60,000"
                                                                     InputProps={{
                                                                        startAdornment : (<InputAdornment disableTypography position="start">
                                                                            SAR 
                                                                        </InputAdornment>)
                                                                     }}
                                                                    />
                                                                </Box>
                                                                <Box className="secondField">
                                                                    <label className="labels">When are you planning to move in?</label>

                                                                    <Box className="dateInput">
                                                                        <div className="inputDateClass">{moment(this.state.moveInDate).format("DD/MM/YYYY")}</div>
                                                                        <input type="date" data-testID="get-new-date" value={this.state.moveInDate} 
                                                                        onChange={this.handleDateChange}
                                                                         className="inputDateSubClass" 
                                                                         />
                                                                        <img
                                                                            className="calenderIcon"
                                                                            src={calender.default}
                                                                            alt="calendar"
                                                                            onClick={this.handleIconClick}
                                                                        />
                                                                    </Box>

                                                                </Box>
                                                                <Box className="thirdField">
                                                                    <label className="labels">Duration</label>
                                                                    <FormControl fullWidth className="inputMon"autoFocus={false} >
                                                                        <Select id={"duration"}
                                                                            autoFocus={false}   
                                                                            style={{padding:'0px !important'}}                
                                                                            value={this.state.duration}
                                                                            onChange={this.handleDurationChange}
                                                                            className="inputMonths">
                                                                            <MenuItem value="6 Months">6 Months</MenuItem>
                                                                            <MenuItem value="12 Months">12 Months</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                                <Box className="buttonCalBox">
                                                                    <button onClick={this.handleSubmit} type = "submit"
                                                                     disabled={isSubmitting} 
                                                                     className="calculateButton">
                                                                        Calculate Now
                                                                    </button>
                                                                </Box>
                                                            </form>
                                                        )}
                                                    </Formik>    
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="thirdRightBox">
                                        <img className="thirdRightBoxImg" src={man_Img.default} />
                                    </Box>
                                </Box>

                                <Box className="forthContainer">
                                    <Box className="forthContLeft">
                                        <Typography className="forthLeftTypoOne">{this.state.whyChooseData.title}</Typography>
                                        <Typography className="forthLeftTypoTwo">{this.state.whyChooseData.description}</Typography>
                                    </Box>
                                    <Box className="forthContRight">
                                        <img className="forthContRightImg" src={women_Img.default} />
                                    </Box>
                                </Box>

                                <Box className="fifthContainer">
                                    <Typography className="fifthTitle">How it works?</Typography>
                                    <Box className="fifthSubCon">
                                        <Box className="fifthBoxFirst">
                                            <img className="fifthMainImg" src={image_first.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">1</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">Sign Up</Typography>
                                            <Typography className="fifthSubHeading">Easily signup using your mobile number</Typography>
                                        </Box>
                                        <img className="arrowDown" src={arrowDown.default} />
                                        <Box className="fifthBox">
                                            <img className="fifthMainImg" src={image_second.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">2</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">User Verification</Typography>
                                            <Typography className="fifthSubHeading">Aqsat will pull your information to verify your identity</Typography>
                                        </Box>
                                        <img className="arrowUp" src={arrowUp.default} />

                                        <Box className="fifthBox">
                                            <img className="fifthMainImg" src={image_third.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">3</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">Credit Scoring</Typography>
                                            <Typography className="fifthSubHeading">Our engine will do its magic to calculate your approved monthly payments</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="fifthSubCon">
                                        <Box className="fifthBox">
                                            <img className="fifthMainImg" src={image_forth.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">4</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">Property & Landlord Verification</Typography>
                                            <Typography className="fifthSubHeading">We will request the property and landlord REGA numbers to verify both</Typography>
                                        </Box>
                                        <img className="arrowDown" src={arrowDown.default} />

                                        <Box className="fifthBox">
                                            <img className="fifthMainImg" src={image_fifth.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">5</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">Renting the Property from the Landlord</Typography>
                                            <Typography className="fifthSubHeading">Aqsat will rent the property from the landlord and settle the annual rent in one payment</Typography>
                                        </Box>
                                        <img className="arrowUp" src={arrowUp.default} />

                                        <Box className="fifthBox">
                                            <img className="fifthMainImg" src={image_sixth.default} />
                                            <Box className="fifthSubImg">
                                                <Typography className="fifthNumber">6</Typography>
                                            </Box>
                                            <Typography className="fifthHeading">Renting the Property to you</Typography>
                                            <Typography className="fifthSubHeading">Aqsat will sub-lease the property to you with 12 easy monthly payments</Typography>
                                        </Box>
                                    </Box>
                                    <button className="buttonSubmitAppTwo">Submit Your Application</button>
                                </Box>
                                <Box className="sixthContainer">
                                    <Box className="sixthMain">
                                        <Typography className="sixthContHeading">Frequently Asked Questions</Typography>
                                        <Box className="sixthFAQbox">
                                            {this.state.FaqData.map((faq: {id: string,attributes: {question:string, answer:string}}, index:number) => (
                                                <Box key={faq.id} className="sixthQuesBox">
                                                    <Box className="sixthBox">
                                                        <Typography className="sixthContQuestions">{faq.attributes.question}</Typography>
                                                        <img className="arrowOpen"
                                                            data-test-id={`faq${index}`}
                                                            src={this.state.openIndex === index ? arrowClose.default : arrowOpen.default}
                                                            onClick={() => this.handleFaqToggle(index)} />
                                                    </Box>

                                                    {this.state.openIndex === index &&
                                                        <Typography className="faqAns">{faq.attributes.answer}</Typography>
                                                    }
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box className="sixthContRight">
                                        <img className="sixthContRightImg" src={key_image.default} />
                                    </Box>
                                </Box>
                            </div>

                        </NavigationMenu>
                    </div>
                </ThemeProvider>
            </MainWrapper> 

        );
    }
    // Customizable Area End
}

// Customizable Area Start
const theme = createTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      body1: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '27px',
        textAlign: 'left',
      },
    },
  });
// Customizable Area End