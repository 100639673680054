import React from "react";

// Customizable Area Start
import { Box, Container, InputAdornment, Typography, Button, TextField, Modal } from '@mui/material';
import { styled, createTheme, ThemeProvider, } from '@mui/material/styles';
import { phoneCountrySvg, AqsatLogo, CrossSvg } from "./assets";
import { Formik, Field,  Form } from 'formik';
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';

const validationSchema = Yup.object({
    mobileNumber: Yup.string()
        .matches(/^\d+$/, 'Please provide a valid mobile number')
        .matches(/^\d{10}$/, 'Phone Number must be at least 10 numbers')
        .required('Please provide a valid mobile number')
        .trim(),
});

const initialValues = {
    mobileNumber: '',
};



const theme = createTheme(
    {
        palette: {
            primary: {
                main: "#fff",
                contrastText: "#fff",
            },
            secondary: {
                main: "#376fd0",
                contrastText: "#fff",
            },
            error: {
                main: "#f44336",
                contrastText: "#fff",
            }
        },
        typography: {
            fontFamily: 'Poppins, sans-serif',
            h1: {
                fontWeight: 700,
                fontSize: "28px",
            },
            h6: {
                fontWeight: 500,
            },

            subtitle1: {
                margin: "20px 0px",
            },
        },
        components: {
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderBottom: "1px solid #CBD5E1",
                        "&.Mui-focused": {
                            borderBottom: "1px solid #CBD5E1",
                        },
                    },
                },
            },
        },
    });
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";

export default class PhoneNumberLogin extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render(

    ) {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <div id="signup_main_block">
                <ThemeProvider theme={theme}>
                    <StyledWrapper>
                        <Box component={'div'} className="imageWrapper" >
                            <img src={AqsatLogo} alt="Aqsat_logo" />
                        </Box>

                        <Container maxWidth="xl" className="mainDivStyle" >
                            <Typography
                                variant="h4"
                                gutterBottom
                                sx={Styles.HeadingStyle}
                            >
                            Registration
                            </Typography>
                            <Formik
                                validationSchema={validationSchema}
                                data-test-id="address-dialog"
                                initialValues={initialValues}
                                onSubmit={(values, {  resetForm }) => {
                                    resetForm();
                                    this.setModal(true)
                                }}
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    isValid,
                                }) => {
                                    return (
                                        <Form className="styledForm" onSubmit={handleSubmit}>
                                            <Field
                                                as={TextField}
                                                variant="standard"
                                                className="PhoneInput"
                                                placeholder="050 123 2345"
                                                name="mobileNumber"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <img src={phoneCountrySvg} alt="country code" className="phoneIconStyle" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mobileNumber}
                                                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                                helperText={touched.mobileNumber && errors.mobileNumber}
                                            />
                                            <Button data-test-id="submitBtn" type="submit" className="LogInBtn" >
                                                Next
                                            </Button>
                                        </Form>
                                    );
                                }}
                            </Formik>

                            <Box className="RegistrationBox">
                                <Typography className="registerBoxFirst" >
                                    Already Have an account?
                                </Typography>
                                <Typography data-test-id="logInBtn" onClick={() => this.handleNavigation('Login')} className="registerBoxSecond">
                                    Login
                                </Typography>

                            </Box>
                            <Modal
                                open={this.state.isVisible}
                                onClose={this.hideModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={modalStyle} >
                                    <Box sx={Styles.Wrapper} >
                                        <Box sx={Styles.crossWrap}>
                                            <Box data-test-id="closeModal" onClick={() => this.hideModal()} component="img" src={CrossSvg} sx={Styles.crossImg} alt="Cross Button" />
                                        </Box>
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            sx={Styles.HeadingStyleOtp}
                                        >
                                            Verify your Number
                                        </Typography>
                                        <Typography sx={Styles.mobileCode}>
                                            We sent a code to 050 123 2345
                                        </Typography>
                                        <Box sx={Styles.OtpInputWrapper}>
                                            <OtpInput
                                                value={this.state.otp}
                                                onChange={this.handleOtpChange}
                                                numInputs={4}
                                                renderInput={(props) => <input  {...props} style={{
                                                    border: this.state.otpError ? '2px solid #E00307' : '2px solid #D7D7D7',
                                                    borderRadius: '16px',
                                                    width: '50px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                }} />}
                                            />
                                        </Box>
                                        {this.state.otpError && (
                                            <Typography color="error" sx={Styles.otpError}>
                                                {this.state.otpError}
                                            </Typography>
                                        )}

                                        {this.state.timer > 0 ? (
                                            <Typography sx={Styles.OtpTimer}>{`0:${this.state.timer}`}</Typography>
                                        ) : (
                                            <Typography sx={Styles.ResendCode}>
                                                {!this.state.otpError && `Didn’t get a code?`}
                                                <Typography data-test-id="resend-otp-button" onClick={this.resendOtp} sx={Styles.ClickResendCode}>
                                                    Click to resend
                                                </Typography>
                                            </Typography>)}
                                        <Button disabled={this.state.otp.length !== 4} sx={{
                                            ...Styles.VerifyButton,
                                            backgroundColor: this.state.otp.length === 4 ? 'blue' : 'gray',
                                            color: this.state.otp.length === 4 ? 'white' : 'darkgray',
                                            '&.Mui-disabled': {
                                                backgroundColor: 'lightgray',
                                                color: 'darkgray'
                                            }
                                        }} onClick={this.validateOtp} >
                                            Verify
                                        </Button>
                                    </Box>
                                </Box>

                            </Modal>
                        </Container>
                    </StyledWrapper>
                </ThemeProvider>
            </div>

            // Customizable Area End
        );
    }

}

// Customizable Area End
const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-54%, -50%)",
    bgcolor: "background.paper",
    borderRadius: '16px',
    marginLeft: '16px',
    marginRight: '16px',

};

const StyledWrapper = styled(Box)({
    "& .modalStyle": {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-54%, -50%)",
        bgcolor: "background.paper",
        borderRadius: '16px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    "& .styledForm": {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        "& .MuiFormHelperText-root": {
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(211, 47, 47)',
        },
    },
    "& .imageWrapper": {
        marginTop: '30px',
        marginBottom: '25px',
        paddingLeft: '16px',
        "@media (min-width: 200px) and (max-width: 600px)": {
            marginTop: '20px',
            paddingLeft: '16px',
        },
        "@media (min-width: 1000px)": {
            marginTop: '30px',
            paddingLeft: '226px',
        },
    },
    "& .mainDivStyle": {
        display: "flex",
        marginTop: "125px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "117px",
        },
        "@media (max-width: 768px)": {
            marginTop: '38px',
        },
    },
    "& .centerContent": {
        textAlign: "center",
    },
    "&. HeadingStyle": {
        fontWeight: 700,
        fontSize: "28px",
        marginBottom: "67px",
        fontFamily: "Poppins",
        lineHeight: "29.33px",
        textAlign: "center",
        "@media (max-width: 600px)": {
            marginTop: '88px',
        },
    },
    "& .forgotPass": {
        marginTop: '5px',
        marginBottom: '36px',
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            marginBottom: "32px",
        },
    },
    "& .PhoneInput": {
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        height: "44px",
        marginBottom: "31px",
        backgroundColor: "transparent",
        "@media (max-width: 600px)": {
            height: "44px",
            marginBottom: "32px",
        },
        "& :active": {
            borderBottom: "2px solid #CCF1FF",
            colbgColor: 'red'
        }
    },
    "& .phoneIconStyle": {
        marginRight: "8px",
    },
    "& .LoginBtn": {
        maxWidth: "520px",
        height: "44px",
        fontSize: "20px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 600px)": {
            width: "100%",
            height: "44px",
            padding: "18px 51px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },
    "& .otpInput": {
        border: "2px solid #1D36BB",
        borderRadius: "16px",
        width: "70px",
        height: "70px",
        marginBottom: "31px",
        marginRight: "10px",
        backgroundColor: "transparent",
        fontFamily: "Poppins",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px 0px 22px",
        fontSize: "35px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        "@media (max-width: 600px)": {
            width: "70px",
            height: "70px",
            marginRight: "10px",
            marginBottom: "0px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    "& .verifyButton": {
        width: "520px",
        height: "53px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "73px",
        textTransform: "none",
        "@media (max-width: 600px)": {
            width: "329px",
            height: "53px",
            padding: "19.5px 133.5px",
            marginBottom: "15px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },
    "& .WrapperStyled": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        position: 'relative',
        padding: '30px',
        "@media (max-width: 600px)": {
            padding: '16px',
            margin: '16px'
        },
    },
    "& .headingStyleOtp": {
        fontWeight: 700,
        fontSize: "28px",
        marginTop: '55px',
        marginBottom: "22px",
        fontFamily: "Poppins",
        lineHeight: "29.33px",
        textAlign: "center",
    },
    "& .crossWrap": {
        display: "flex",
        alignItems: "end",
        justifyContent: 'flex-end',
        width: "100%",
        position: "relative",
    },
    "& .crossImg": {
        cursor: 'pointer'
    },
    "& .mobileCode": {
        marginBottom: "37px",
        "@media (max-width: 600px)": {
            width: '100%'
        },
    },
    "& .OtpInputWrapper": {
        display: "flex",
        alignItems: "row",
        justifyContent: "center",
        "@media (max-width: 600px)": {
            marginTop: '44px',
        },
    },
    "& .ResendCode": {
        marginBottom: "37px",
        display: "flex",
        "@media (max-width: 600px)": {
            marginBottom: "69px",
            display: "flex",
        },
    },
    "& .ClickResendCode": {
        marginLeft: "5px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        borderBottom: "1px solid black",
    },
    "& .otpError": {
        color: 'rgb(211, 47, 47)',
        marginBottom: '10px',
        fontSize: '14px',
    },
    "& .RegistrationBox": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '39px',
        gap: '15px',
        "& .registerBoxFirst": {
            fontWeight: 400,
            fontSize: '20px'
        },
        "& .registerBoxSecond": {
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '20px'
        },
    }
});

const Styles = {
    mainDivStyle: {
        display: "flex",
        marginTop: "125px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "117px",
        },
        "@media (max-width: 768px)": {
            marginTop: '38px',
        },
    },
    centerContent: {
        textAlign: "center",
    },
    HeadingStyle: {
        fontWeight: 700,
        fontSize: "28px",
        marginBottom: "67px",
        fontFamily: "Poppins",
        lineHeight: "29.33px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "88px",
        },
    },
    forgotPass: {
        marginTop: '5px',
        marginBottom: '36px',
        maxWidth: "520px",
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            marginBottom: "32px",
        },
    },
    phoneIconStyle: {
        marginRight: "8px",
    },
    LoginBtn: {
        maxWidth: "520px",
        height: "44px",
        fontSize: "20px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "44px",
            padding: "18px 51px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },

    mainModalStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    otpInput: {
        border: "2px solid #1D36BB",
        borderRadius: "16px",
        width: "70px",
        height: "70px",
        marginBottom: "31px",
        marginRight: "10px",
        backgroundColor: "transparent",
        fontFamily: "Poppins",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px 0px 22px",
        fontSize: "35px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            width: "70px",
            height: "70px",
            marginRight: "10px",
            marginBottom: "0px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    VerifyButton: {
        width: "520px",
        height: "53px",
        padding: "19.5px 236px",
        borderRadius: "6px",
        backgroundColor: "#1D36BB",
        color: "#fff",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "73px",
        textTransform: "none",
        [theme.breakpoints.down("sm")]: {
            width: "329px",
            height: "53px",
            padding: "19.5px 133.5px",
            marginBottom: "15px",
        },
        "&:hover": {
            backgroundColor: "#1D36BB",
            color: "#fff",
            boxShadow: "none",
        },
    },

    Wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        position: 'relative',
        padding: '30px',
        flexWrap: 'wrap',
        [theme.breakpoints.down("sm")]: {
            padding: '16px',
            margin: '16px'
        },
    },
    HeadingStyleOtp: {
        fontWeight: 700,
        fontSize: "28px",
        marginTop: '55px',
        marginBottom: "22px",
        fontFamily: "Poppins",
        lineHeight: "29.33px",
        textAlign: "center",
    },
    crossWrap: {
        display: "flex",
        alignItems: "end",
        justifyContent: 'flex-end',
        width: "100%",
        position: "relative",
    },
    crossImg: {
        cursor: 'pointer'
    },
    mobileCode: {
        marginBottom: "37px",
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        },
    },
    OtpInputWrapper: {
        display: "flex",
        justifyContent: 'center',
        marginBottom: "44px",
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            marginBottom: "44px",
        },
        "div": {
            gap: '10px',
            justifyContent: 'center',
        }
    },
    OtpBoxes: {
        display: 'flex',
        justifyContent: 'center',
        width: '100px',
        backgroundColor: 'red !important',
        marginRight: '10px !important'
    },
    ResendCode: {
        marginBottom: "37px",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "69px",
            display: "flex",
        },
    },
    OtpTimer: {
        color: '#2C2C2E',
        marginBottom: '37px',
        lineHeight: '29.33px',
        size: '16px',
        fontWeight: 700
    },
    ClickResendCode: {
        marginLeft: "5px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "29.33px",
        textAlign: "center",
        borderBottom: "1px solid black",

        [theme.breakpoints.down("sm")]: {
        },
    },
    otpError: {
        color: 'rgb(211, 47, 47)',
        marginBottom: '10px',
        fontSize: '14px',
    },


}




// Customizable Area End
